<template>
  <div id="app">
    <router-view />
    <el-card class="account-box" :style="{ right: isShow ? 0 : '-350px' }">
      <div slot="header" class="clearfix flex-between">
        <div>
          <i
            class="el-icon-warning"
            style="color: #f56c6c; font-size: 18px; margin-right: 5px"
          ></i>
          <span style="font-size: 14px">平台账号异常列表</span>
          <el-button type="text" style="margin-left: 10px" @click="toLogin"
            >去登录</el-button
          >
        </div>
        <i class="el-icon-close" @click="isShow = false"></i>
      </div>
      <div
        v-for="item in abnormalList"
        :key="item.uname"
        class="account-item flex-between"
      >
        <p class="account-info">{{ item.platformStr }} : {{ item.uname }}</p>
        <template>
          <el-tag v-if="item.status === 1" type="warning">密码错误</el-tag>
          <el-tag v-else-if="item.status === 2" type="danger">登录过期</el-tag>
        </template>
      </div>
      <!--      <template v-if="abnormalWxList.length">-->
      <!--        <el-divider content-position="left">微信账号-->
      <!--        </el-divider>-->
      <!--        <div v-for="item in abnormalWxList" :key="item.accountChannelId" class="account-item flex-between">-->
      <!--          <p class="account-info">{{item.channelName }}</p>-->
      <!--          <el-tag type="danger">登录过期</el-tag>-->
      <!--        </div>-->
      <!--      </template>-->
    </el-card>
  </div>
</template>

<script>
import {
  getAbnormalAccount,
  getOfficialList,
  getAllUsers
} from './api/account'
import { mapActions, mapGetters } from 'vuex'
import { getCookie, removeCookie, setCookie } from './assets/js/cookie'
import { GetRequest, isInstallChromeExtension } from './assets/js/utils'
import { login, getPluginInfo } from './api/user'

let timer = null
export default {
  data () {
    return {
      abnormalWxList: [],
      abnormalList: [],
      isShow: false,
      extendId: process.env.VUE_APP_BASE_extendId,
      notifyCount: 0, // 插件提示的次数
      curVersion: 0,
    }
  },
  mounted () {
    const { authorizationToken } = GetRequest(location.href) // 获取登录查询字符串数据 如果存在跳转至登录页面
    if (authorizationToken) {
      removeCookie('cps_token')
      this.$router.replace('/login')
      login({ authorizationToken }).then((res) => {
        if (res) {
          const { token, ...userInfo } = res
          setCookie('cps_token', token)
          setCookie('userInfo', JSON.stringify(userInfo))
          this.setUserInfo(userInfo)
          this.$notify({
            title: '成功',
            message: '登录成功',
            type: 'success'
          })
          this.resetHistory() // 将首页推入到路由历史
          this.$router.replace('/')
          // 初始化当前用户下公众号、所有用户、非正常用户
          getOfficialList().then((res) => {
            this.setOfficialList(res)
          })
          getAllUsers().then((res) => {
            this.setUserList(res)
          })
          this.getAbnormalList()
        } else {
          this.$message.error('登录失败')
        }
      })
    } else if (getCookie('cps_token')) {
      // 若没有查询字符串 查询本地token并获取当前用户下公众号、所有用户、非正常用户
      getOfficialList().then((res) => {
        this.setOfficialList(res)
      })
      getAllUsers().then((res) => {
        this.setUserList(res)
      })
      this.getAbnormalList()
    }

    const state = localStorage.getItem('stateData') // 查询本地state数据 有择保存至vuex并删除本地state 隐藏数据
    if (state) {
      try {
        this.$store.replaceState(
          Object.assign(
            this.$store.state,
            JSON.parse(localStorage.getItem('stateData'))
          )
        )
        localStorage.removeItem('stateData')
      } catch (e) {
        console.log(e)
      }
    }
    // 在本地关闭或刷新之前保存state数据到本地 以方便后续查询并保存
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('stateData', JSON.stringify(this.$store.state))
    })

    if (timer) {
      clearInterval(timer)
      timer = null
    }
    timer = setInterval(() => {
      // 每十分钟查询一次不正常用户列表
      if (getCookie('cps_token')) {
        this.getAbnormalList()
      }
    }, 600000)
  },
  watch: {
    notifyCount: {
      handler (newV) {
        if (newV >= 2) {
          setTimeout(() => {
            this.notifyCount = 0
          }, 1000 * 60 * 60 * 4)
        }
      }
    }
  },
  computed: {
    ...mapGetters(['routerHistory']),
  },
  methods: {
    toLogin () {
      if (this.$route.fullPath !== '/account-management/platform-account') {
        this.$router.push('/account-management/platform-account')
      }
    },
    getAbnormalList () {
      getAbnormalAccount().then((res) => {
        this.abnormalList = res
        this.isShow = res.length !== 0
      })
    },
    notifyInfo (res) {
      this.$notify({
        title: '插件更新!',
        dangerouslyUseHTMLString: true,
        message: `检测到插件有新版本(${res[0].version}) -<span style='color:#e6a23c'>现有版本请做好备份！！！</span><br>
                  ${res[0].content}<br>
                  <a style="color:#409eff" href=\'https://cpsnovel.chongqingtusi.com/download/novel_put.zip\' download=\'https://cpsnovel.gaohaiwl.com/download/novel_put.zip\'>点击下载</a>`
      })
    },
    // 版本比较
    compareVersion (v1, v2) {
      const s1 = `${v1}`.split('.')
      const s2 = `${v2}`.split('.')
      let a1 = []; let a2 = []
      for (let i = 0; i < Math.max(s1.length, s2.length); i++) {
        let vs1 = s1[i]
        let vs2 = s2[i]
        if (!vs1) {
          vs1 = `${Array.from({ length: s2[i].length }, () => 0).join('')}`
        }
        if (!vs2) {
          vs2 = `${Array.from({ length: s1[i].length }, () => 0).join('')}`
        }
        if (i === 0) {
          a1 = [...a1, vs1]
          a2 = [...a2, vs2]
        } else {
          const iLen1 = vs1.length
          const iLen2 = vs2.length
          if (iLen1 === iLen2) {
            a1.push(vs1)
            a2.push(vs2)
          } else {
            if (iLen1 < iLen2) {
              const v1 = `${vs1}${Array.from({ length: iLen2 - iLen1 }, () => 0).join('')}`
              a1.push(v1)
              a2.push(vs2)
            } else if (iLen1 > iLen2) {
              const v2 = `${vs2}${Array.from({ length: iLen1 - iLen2 }, () => 0).join('')}`
              a2.push(v2)
              a1.push(vs1)
            }
          }
        }
      }
      for (let i = 0; i < Math.max(a1.length, a2.length); i++) {
        const v1 = a1[i]
        const v2 = a2[i]
        if (+v1 < +v2) {
          return 1
        }
        if (+v1 > +v2) {
          return -1
        }
      }
      return 0
    },
    ...mapActions({
      setOfficialList: 'setOfficialList',
      setUserList: 'setUserList',
      setRouterHistory: 'setRouterHistory',
      addRouterHistory: 'addRouterHistory',
      setUserInfo: 'setUserInfo',
      resetHistory: 'resetHistory'
    })
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.account-box {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 300px;
  bottom: 100px;
  right: 0;
  transition: all 0.6s;
  z-index: 3000;
}

.account-info {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-item {
  font-size: 14px;
  margin-top: 10px;
}

.el-card__header {
  padding: 8px 12px;
}
</style>
