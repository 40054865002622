<template>
  <el-container style="height: 100vh">
    <el-header
      class="box-shadow-light-grey"
      height="54px"
      style="z-index: 1; padding: 0 10px 0 18px; background-color: #fff"
    >
      <div class="header-ber">
        <div class="row">
          <div class="logo">
            <img src="../assets/logo.jpg" style="width: 24px; height: 24px" alt />
            <h3 class="logo-title">小说快应用投放系统</h3>
          </div>
          <i
            class="iconfont icon-shousuo2"
            :class="isCollapsed ? 'Collapsed' : ''"
            @click="setCollapsed(!isCollapsed)"
            style="
              display: inline-block;
              color: #1f2d3d;
              margin-left: 7px;
              font-size: 18px;
            "
          ></i>
        </div>
        <div class="row" style="font-size: 14px">
          <div class="custom-carousel">
            <el-carousel height="52px" direction="vertical" :autoplay="true">
              <el-carousel-item
                v-for="(item, index) in failedList"
                :key="index"
                @click.native="gotoFailedPage(item)"
              >
                <!-- <i class='el-icon-bell' style='font-size:18px;color:#00bf8a'></i> -->
                <div class="medium">
                  <i
                    class="el-icon-bell"
                    style="
                      font-size: 18px;
                      color: #fd5d5a;
                      vertical-align: text-bottom;
                      margin-right: 9px;
                    "
                  ></i>
                  <span>{{ item.frontMessage }}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="global-year">
            <date-picker
              type="yearrange"
              v-model="globalYearSchedule"
              value-format="yyyy"
              range-separator="-"
              start-placeholder="开始年份"
              end-placeholder="结束年份"
              :picker-options="pickerOptions"
            ></date-picker>
          </div>
          <span class="account-btn" @click="showAccount = true">
            <i class="iconfont icon-shuji1" style="font-size: 18px; color: #22c7b1" title="书城账号"></i>
          </span>
          <!-- <span class="avatar">{{ userInfo.name.slice(-1) }}</span> -->
          <span class="user-name">{{ userInfo.name }}</span>
          <span class="logout-cont" @click="handleLogout" style="color: #1f2d3d">
            <i class="iconfont icon-exittuichu" style="margin-right: 8px; color: #8492a6"></i>退出
          </span>
        </div>
      </div>
    </el-header>
    <el-container class="lay-main">
      <el-aside
        class="nav-aside"
        :width="isCollapsed ? '60px' : '239px'"
        style="transition: all 0.3s; background: #fff"
      >
        <el-menu
          class="main-menu el-menu-vertical-demo-chi"
          :class="{ tyCollapsed: isCollapsed }"
          router
          @select="handleMenuSelect"
          :collapse="tmpCollapsed"
          :default-active="activeMenu"
          style="border: 0; font-weight: bold"
        >
          <menu-item v-for="(menu, i) in menuList" :key="i" :item="menu" />
        </el-menu>
      </el-aside>
      <el-main class="main-cont" id="mainCont">
        <transition name="slide-fade" mode="out-in">
          <router-view
            :params="
              this.$store.getters.currentRouteParams(this.$route.fullPath)
            "
          />
        </transition>
      </el-main>
    </el-container>

    <account-list :show="showAccount" @close="showAccount = false" />
  </el-container>
</template>

<script>
import AccountList from "./account";
import { getTipItemList } from "@/api/service";
// import UserAvatar from '../components/UserAvatar'
import { formatTime } from "@/assets/js/utils";
import Watermark from "@/assets/js/watermark";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { logout } from "../api/user";
import { removeCookie } from "../assets/js/cookie";
import { menuList } from "../assets/js/nav";
import SidebarItem from "../components/SidebarItem";
import menuItem from "../components/menuItem";
export default {
  name: "layout",
  data() {
    return {
      activeMenu: this.$route.path,
      isSet: false,
      setForm: {},
      menuList,
      showAccount: false,
      failedList: [], // 客服消息发送失败的消息合集
      pageSize: 5,
      timer: null,
      curRouteIndex: null,
      breakFlag: false,
      tmpCollapsed: true,
      years: ['2022', '2022'],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  watch: {
    $route(newVal) {
      // 解决二级菜单不收回bug
      this.tmpCollapsed = false;
      setTimeout(() => {
        this.tmpCollapsed = true;
      }, 30);
      this.activeMenu = newVal.path;
    },
    isConfirmFailedInfo(newVal) {
      if (newVal) {
        this.getTipsList();
        this.setIsConfirmFailedInfo(false);
      }
    },
  },
  mounted() {
    this.getTipsList();
    const tody = formatTime(new Date(), "yyyy-MM-dd");
    Watermark.set(this.$store.state.userInfo.name + " " + tody, "mainCont");
    this.getUserInfo();
    this.setInterValTimer();
    this.$on("hook:beforeDestroy", () => {
      clearInterval(this.timer);
      this.timer = null;
    });
  },
  computed: {
    ...mapGetters(["userInfo", "isCollapsed", "routerHistory"]),
    ...mapState(["isConfirmFailedInfo"]),
    globalYearSchedule: {
      get() {
        return this.$store.state.stateYears;
      },
      set(val) {
        this.setGlobalYearSchedule(val);
      },
    },
  },
  methods: {
    ...mapMutations(["setIsConfirmFailedInfo", "setGlobalYearSchedule"]),
    getTipsList() {
      getTipItemList({
        page: 1,
        pageSize: this.pageSize,
        status: 0,
      }).then((res) => {
        this.failedList = res.list;
        this.setIsConfirmFailedInfo(false);
      });
    },
    gotoFailedPage(val) {
      this.$router.push({
        name: "SendFailed",
        params: {
          accountChannelId: val.accountChannelId ? val.accountChannelId : null,
        },
      });
    },
    setInterValTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.getTipsList();
        }, 60 * 1000 * 5);
      }
    },
    handleMenuSelect(path) {
      // // return false
      // ;
      // ;
    },
    handleLogout() {
      this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        logout().then(() => {
          this.$notify.warning("退出登录");
          removeCookie("cps_token");
          localStorage.clear();
          this.setUserInfo({});
          this.$router.replace("/login");
        });
      });
    },
    getFontColor(val) {
      if ("color" in val) {
        return {
          color: `${val.color}`,
        };
      }
    },
    ...mapActions({
      setUserInfo: "setUserInfo",
      getUserInfo: "getUserInfo",
      setCollapsed: "setCollapsed",
    }),
  },
  components: {
    AccountList,
    SidebarItem,
    menuItem,
  },
  // created() {
  //   this.$store.commit("clearHistory");
  // },
};
</script>

<style scoped lang="scss">
.main-menu {
  // font-size:12px !important;
  /deep/ .el-menu-item {
    color: #1f2d3d !important;
    // font-size:inherit
  }
  /deep/ .el-submenu__title {
    color: #1f2d3d !important;
    // font-size: inherit;
  }
}
h3 {
  text-align: right;
  cursor: pointer;
}
.medium {
  cursor: pointer;
}
.custom-carousel {
  width: 520px;
  line-height: 52px;
  color: #fd5d5a;
}
.account-btn {
  cursor: pointer;
  margin-left: 34px;
  margin-right: 34px;
  color: #2888e1;
}
.avatar {
  display: inline-block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #00bf8a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  // font-weight:
  font-size: 16px;
}
.user-name {
  margin-left: 9px;
  font-size: 14px;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  color: #fa96a3;
  cursor: pointer;
  opacity: 0;
  animation: appear 0.3s forwards;
  animation-delay: 1s;
}

.title {
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #1c9399;
  /*border-bottom: 1px solid #ececec;*/
}

.header-ber {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #ccc;
  .logo {
    display: flex;
    align-items: center;
    .logo-title {
      display: inline-block;
      color: #1f2d3d;
      font-size: 16px;
      margin-left: 10px;
      margin-right: 50px;
    }
  }
  .collapse-icon {
    color: #1f2d3d;
  }
}

.lay-main {
  height: calc(100vh - 54px);
}
.main-cont {
  padding: 16px;
  position: relative;
  background-color: #f6f8f9;
}

.logout-cont {
  margin-left: 34px;
  cursor: pointer;
}
.el-menu-item i,
.el-submenu i {
  font-size: 16px;
  font-weight: bold;
  margin-left: 22px;
  margin-right: 9px;
  color: #1f2d3d;
}
// 菜单设置
// .el-menu {
//   color: #8492a6;
// }

.nav-aside {
  // padding-left: 20px;
  /deep/ .el-menu-item {
    // color: #5f6e82;
    font-size: 15px;
    padding-left: 0 !important;
    &:hover {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  /deep/ .el-submenu__title {
    padding-left: 0 !important;
    &:hover {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}

/deep/.el-submenu__title.is-active {
  background-color: #eceff2;
  color: #00bf8a !important;
  font-weight: bold;
  border-right: 3px solid #00bf8a;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  > span {
    color: #00bf8a !important;
  }
}

/deep/ .el-menu-item.is-active {
  background-color: #eceff2;
  color: #00bf8a !important;
  font-weight: bold;
  border-right: 3px solid #00bf8a;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  span {
    color: #00bf8a !important;
  }
  i {
    color: #00bf8a !important;
  }
}

/deep/ .el-tooltip {
  padding: 0 !important;
}
/deep/.el-submenu .el-submenu__title {
  font-size: 15px;
  color: #8492a6;
}
/deep/.el-submenu .el-menu-item {
  padding-left: 48px !important;
}
/deep/.el-submenu .el-submenu .el-submenu__title {
  padding-left: 48px !important;
  .el-submenu__icon-arrow {
    // left:8px;
  }
}
/deep/.el-submenu .el-submenu .el-menu-item {
  padding-left: 72px !important;
  padding-right: 48px !important;
  // margin-left: 20px;
  border-radius: 3px 0px 0px 3px;
}
/deep/.el-submenu .el-submenu .el-submenu .el-submenu__title {
  font-size: 16px;
  // color: #1f2d3d;
}
/deep/ .el-submenu .el-menu-item {
  height: 36px;
  line-height: 36px;
  margin: 16px 0;
}
/deep/ .el-submenu__icon-arrow {
  // position: relative;
  // top: 5px;
  // left: 13px;
  // transform: none;
}
// 表格颜色设置
/deep/.table-header th {
  background-color: #f5f8fc !important;
}
/deep/.el-table__row-- d td {
  background-color: #f5f8fc !important;
}

/deep/ .el-carousel__indicators {
  display: none;
}
.collapse-icon {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
}

.history {
  position: relative;
  height: 39px;
  line-height: 35px;
  /*background: rgba(195, 195, 195, .11);*/
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
    width: 3px;
    height: 3px;
    border-radius: 10px;
  }

  &:hover::-webkit-scrollbar {
    display: block;
  }

  .el-tag {
    margin-right: 10px;
    cursor: pointer;
  }

  .el-tag--info {
    background: #ffffff;
    color: #888888;
    border-color: #e1e1e1;
    border-radius: 0;
    vertical-align: middle;

    &.router-link-exact-active {
      background: #1c9399;
      color: #ffffff;
      border-color: #1c9399;

      .el-icon-close {
        color: #ffffff;
      }
    }
  }
}

.clear-router {
  color: #e6a23c;
  margin-right: 10px;
  cursor: pointer;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.icon-shousuo2 {
  transition: all 0.3s;
}
.Collapsed {
  transform: rotate(180deg);
}
.el-menu-vertical-demo-chi {
  width: 100% !important;
}
/deep/ .tyCollapsed .father-span {
  width: 0 !important;
  height: 0 !important;
  visibility: hidden !important;
}
</style>
